import { loadManifest } from '@angular-architects/module-federation';

export const getEnvExtension = (env?: string): string => {
  let ext = '.json';
  switch (true) {
    case env === 'TCTSI' || window.location.hostname.includes('localhost'):
      ext = '.local.json';
      break;
    case env === 'TCTSI' || window.location.hostname.includes('-i.'):
      ext = '.i.json';
      break;
    case env === 'TCTS5' || window.location.hostname.includes('-5.'):
      ext = '.5.json';
      break;
    case env === 'TCTSS' || window.location.hostname.includes('-s.'):
      ext = '.uat.json';
      break;
    default:
      ext = '.prod.json';
      break;
  }
  return ext;
};

loadManifest(`./assets/mfe-manifests/mfe.manifests${getEnvExtension()}`)
  .catch((err) => console.error(err))
  .then(() => import('./bootstrap'))
  .catch((err) => console.error(err));
